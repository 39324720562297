<template>
  <div id="policy-container">
    <div class="title">Segmentasyon</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Ana Sayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Segmentasyon</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="buttons-group">
        <el-button class="add" @click="setItem(null)"><i class="icon-plus"></i></el-button>
      </div>
    </div>
    <div class="main-price" v-if="user.Role == 12">
      <div>
        <div class="price">
          <div class="main-price-title">Ana Fiyat</div>
          <el-input v-model="MainPrice" class="main-price-input"> </el-input>
          <el-button class="save-price-btn" type="primary" @click="updateMainPrice()"><i class="icon-send"></i>Kaydet</el-button>
        </div>
        <span class="error">{{ $errorMessage("MainPrice", ValidationErrors) }}</span>
      </div>

      <div>
        <div class="price">
          <div class="main-price-title">KDV Oranı %</div>
          <el-input v-model="TaxRate" class="main-price-input"> </el-input>
          <el-button class="save-price-btn" type="primary" @click="updateTaxRate()"><i class="icon-send"></i>Kaydet</el-button>
        </div>
        <span class="error">{{ $errorMessage("TaxRate", ValidationErrors) }}</span>
      </div>
      <div>
        <div class="price">
          <div class="main-price-title">Ücret</div>
          <div class="is-free-style">
            <span class="error">Ücretsiz yapıldığında ek ödeme kısmı açılır.</span>
            <el-switch v-model="isFree" @change="updateAppointmentIsFree()" active-text="Ücretsiz" inactive-text="Ücretli" active-color="#234A98"> </el-switch>
          </div>
        </div>
        <span class="error">{{ $errorMessage("IsFree", ValidationErrors) }}</span>
      </div>
      <div>
        <div class="price">
          <div class="main-price-title">Mobil Ödeme</div>
          <div class="is-free-style">
            <el-switch v-model="isMobilePayment" @change="updateMobilePayment()" active-text="Aktif" inactive-text="Pasif" active-color="#234A98"> </el-switch>
          </div>
        </div>
        <span class="error">{{ $errorMessage("isMobilePayment", ValidationErrors) }}</span>
      </div>
    </div>

    <div class="all-data">
      <div class="list-table">
        <el-table :data="allSegment" :fit="true" :stripe="false">
          <el-table-column label="Segmentasyon Adı"   >
            <template slot-scope="scope"> {{ scope.row.Title }} </template>
          </el-table-column>
          <el-table-column label="İndirim Tutarı" width="150px">
            <template slot-scope="scope"> {{ scope.row.Discount }} {{ scope.row.IsDiscountPercent ? "%" : "TL" }} </template>
          </el-table-column>
          <el-table-column label="Ücretsiz Sayısı" width="150px">
            <template slot-scope="scope"> {{ scope.row.FreeCount }} </template>
          </el-table-column>
          <el-table-column label="Başlangıç" width="150px">
            <template slot-scope="scope">
              <template v-if="scope.row.SegmentStartDate != null"> {{ $moment(scope.row.SegmentStartDate).format("DD.MM.YYYY") }} </template>
              <template v-else> Belirtilmemiş </template>
            </template>
          </el-table-column>
          <el-table-column label="Bitiş" width="150px">
            <template slot-scope="scope">
              <template v-if="scope.row.SegmentEndDate != null"> {{ $moment(scope.row.SegmentEndDate).format("DD.MM.YYYY") }} </template>
              <template v-else> Belirtilmemiş </template>
            </template>
          </el-table-column>
          <el-table-column label="Durum" width="200px" align="flex-end">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.IsActive" @change="setActivity(scope.row)" active-text="Aktif" inactive-text="Pasif" active-color="#234A98"> </el-switch>
            </template>
          </el-table-column>
          <el-table-column width="80px" align="flex-end">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link actions-menu">
                  <i class="icon-dots"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span style="display: block" @click="setItem(scope.row.ID)">Düzenle</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided><span class="tex-danger" style="display: block" @click="segmentationDeleteQuery(scope.row.ID)">Sil</span></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>

          <template slot="empty">
            <div class="lottie-container">
              <v-lottie-player name="noData" loop path="/lottie/no-data.json" />
              <div class="no-data-text">Segmentasyon bulunamadı.</div>
              <div class="no-data-text">Herhangi bir segmantasyon kaydı bulunamamıştır.</div>
            </div>
          </template>
        </el-table>
      </div>

      <el-dialog :visible.sync="addUpdateDialog" width="30%" :close-on-click-modal="false">
        <div class="title">{{ form.Id == null ? "Segmantasyon Ekle" : "Segmantasyon Güncelle" }}</div>
        <div class="close" @click="addUpdateDialog = false">
          <i class="icon-close"></i>
        </div>
        <div class="body">
          <div class="input-container">
            <label>Segmentasyon Adı</label>
            <el-input v-model="form.Title"></el-input>
            <span class="error">{{ $errorMessage("Title", ValidationErrors) }}</span>
          </div>
          <!-- !!! FC 17/02/24 ++ Tarih kontrolu ilavesi-->
          <div class="input-container">
            <label>Türü</label>
            <el-switch v-model="form.IsDiscount" active-text="İndirim" inactive-text="Artış" active-color="#234A98"> </el-switch>
            <span class="error">{{ $errorMessage("CheckDates", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>Tip</label>
            <el-switch v-model="form.IsDiscountPercent" active-text="Yüzdeli" inactive-text="Fiyat" active-color="#234A98"> </el-switch>
            <span class="error">{{ $errorMessage("CheckDates", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>{{ form.IsDiscountPercent ? "Yüzde" : "Tutar" }}</label>
            <el-input type="number" v-model.number="form.Discount"></el-input>
            <span class="error">{{ $errorMessage("Discount", ValidationErrors) }}</span>
          </div>
          <div class="input-container">
            <label>Ücretsiz Sayısı</label>
            <el-input type="number" v-model.number="form.FreeCount"></el-input>
            <span class="error">{{ $errorMessage("FreeCount", ValidationErrors) }}</span>
          </div>
          <!-- !!! FC 17/02/24 ++ Tarih kontrolu ilavesi-->
          <div class="input-container">
            <label>Tarih Kontrolü</label>
            <el-switch v-model="form.CheckDates" active-text="Yapılsın" inactive-text="Yapılmasın" active-color="#234A98"> </el-switch>
            <span class="error">{{ $errorMessage("CheckDates", ValidationErrors) }}</span>
          </div>

          <div class="second">
            <!--:default-time="['03:00:00', '23:59:59']" girilebilire-->
            <el-date-picker v-model="DateRange" align="center" format="dd.MM.yyyy" type="daterange" range-separator="-" start-placeholder="Başlangıç" end-placeholder="Bitiş Tarihi" prefix-icon="icon-calendar" :disabled="form.CheckDates == false"> </el-date-picker>
          </div>

          <div class="input-container">
            <label>Durum</label>
            <el-switch v-model="form.IsActive" active-text="Aktif" inactive-text="Pasif" active-color="#234A98"> </el-switch>
            <span class="error">{{ $errorMessage("IsActive", ValidationErrors) }}</span>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="button-group-admin">
            <el-button class="cancelReportAdmin" @click="addUpdateDialog = false">İptal</el-button>
            <el-button class="confirmReportAdmin" type="primary" @click="addUpdateSegmentation()"><i class="icon-send"></i>Kaydet</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        Id: null,
        Title: null,
        Discount: null,
        IsActive: true,
        CheckDates: false,
        SegmentStartDate: null,
        SegmentEndDate: null,
        IsDiscount: true,
      },
      allSegment: [],
      addUpdateDialog: false,
      onSave: false,
      ValidationErrors: [],
      MainPrice: null,
      TaxRate: null,
      isFree: false,
      DateRange: [null, null],
      isMobilePayment: false,
      isDiscountPercent: false,
      FreeCount: null,
    };
  },

  async beforeMount() {
    await this.getData();
    await this.getMainPrice();
    await this.getTaxRate();
    await this.getAppointmentIsFree();
    await this.getMobilePaymentEnabled();
  },

  computed: {
    filter() {
      return this.$store.getters.getPolicyFilter;
    },

    getEnums() {
      return this.$store.getters.getEnums;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    async getData() {
      var res = await this.$client.post("/Segment/List", { SearchTerm: null, Page: 1, PageSize: 1000 });
      this.allSegment = res.data.Data.Items;
      //console.log(this.allSegment);
    },
    async addUpdateSegmentation() {
      this.ValidationErrors = [];
      // this.onSave = true;

      this.form.SegmentStartDate = null;
      this.form.SegmentEndDate = null;
      if (this.form.CheckDates) {
        if (this.DateRange != null && this.DateRange[0] != null && this.DateRange[1] != null) {
          //console.log("Date pıcker den donen : ");
          //console.log(this.DateRange);

          //this.form.SegmentStartDate = this.DateRange[0];
          //this.form.SegmentEndDate = this.DateRange[1];
          //!!! FC 21/02/24++  Not :
          //el-date-picker local time çevirdiğinden,serverde gün kaymaması için saati koruyarak(utc.(true))
          //utc ye çeviriyoruz,çünku segmentasyon günlük kontrol edilecek
          //aksi takdirde 01.02.2024, serverde 31.01.2024T21:00 oluyor. Son tarihte aynı şekilde
          this.form.SegmentStartDate = this.$moment(this.DateRange[0]).utc(true);
          this.form.SegmentEndDate = this.$moment(this.DateRange[1]).utc(true);
        } else {
          this.$message.error("Tarih kontrolü seçilmiş,fakat Başangıç ve Bitiş tarihi girilmemiş!");
          return;
        }
      }
      //console.log("Gonderilen : ");
      //console.log(this.form);
      var res = await this.$client.post("/Segment/AddOrUpdate", this.form);

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
        // this.onSave = false;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
        // this.onSave = false;
        this.addUpdateDialog = false;
        this.getData();
      }
    },

    async segmentationDeleteQuery(id) {
      this.$confirm("Segmentasyon  kaydı ve onun Kullanıcılar ve Kurum atamaları  silinecektir. Devam edilsin mi?", "Dikkat", {
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
        type: "warning",
      })
        .then(() => {
          this.deleteSegmentation(id);
        })
        .catch(() => {});
    },

    async deleteSegmentation(id) {
      var res = await this.$client.post("/Segment/Delete", { ID: id });

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
        // this.onSave = false;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
        // this.onSave = false;
        this.addUpdateDialog = false;
        this.getData();
      }
    },

    setItem(id) {
      this.ValidationErrors = [];

      if (id == null) {
        this.form.Id = null;
        this.form.Title = null;
        this.form.IsActive = true;
        this.form.Discount = null;
        this.form.CheckDates = false;
        this.form.SegmentStartDate = null;
        this.form.SegmentEndDate = null;
        this.form.IsDiscount = true;
        this.DateRange = [];
      } else {
        var item = this.allSegment.find((x) => x.ID == id);
        this.form.Id = item.ID;
        this.form.Title = item.Title;
        this.form.IsActive = item.IsActive;
        this.form.Discount = item.Discount;
        this.form.CheckDates = item.CheckDates;
        this.form.SegmentStartDate = item.SegmentStartDate;
        this.form.SegmentEndDate = item.SegmentEndDate;
        this.form.IsDiscount = item.IsDiscount;

        this.DateRange = [];
        if (item.CheckDates && item.SegmentStartDate != null && item.SegmentEndDate != null) {
          this.DateRange[0] = item.SegmentStartDate;
          this.DateRange[1] = item.SegmentEndDate;
        }
      }
      this.addUpdateDialog = true;
    },

    async setActivity(item) {
      var res = await this.$client.post("/Segment/AddOrUpdate", item);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
      }
    },
    async getMainPrice() {
      var res = await this.$client.post("Admin/GetMainPrice");
      this.MainPrice = res.data.Data.MainPrice;
    },
    async updateMainPrice() {
      var res = await this.$client.post("/Admin/SetMainPrice", { MainPrice: this.MainPrice });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
      }
    },
    async getTaxRate() {
      var res = await this.$client.post("Admin/GetTaxRate");
      this.TaxRate = res.data.Data.TaxRate;
    },
    async updateTaxRate() {
      var res = await this.$client.post("/Admin/SetTaxRate", { TaxRate: this.TaxRate });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
      }
    },
    async getAppointmentIsFree() {
      var res = await this.$client.post("Admin/GetAppointmentIsFree");
      this.isFree = res.data.Data.IsFree;
    },
    async getMobilePaymentEnabled() {
      var res = await this.$client.post("Admin/GetMobilePaymentEnabled");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.isMobilePayment = res.data.Data.IsMobilePayment;
      }
    },
    async updateAppointmentIsFree() {
      var res = await this.$client.post("/Admin/SetAppointmentIsFree", { IsFree: this.isFree });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
      }
    },
    async updateMobilePayment() {
      console.log("isMobilePayment : " + this.isMobilePayment);
      var res = await this.$client.post("/Admin/SetMobilePaymentEnabled", { IsMobilePayment: this.isMobilePayment });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.ValidationErrors = res.data.ValidationErrors;
      } else {
        this.$message.success(res.data.Message);
        this.ValidationErrors = [];
      }
    },
  },
};
</script>

<style lang="less">
#policy-container {
  padding: 42px 30px;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
      }

      .delete {
        background: #eaedf0;

        i {
          background: #44566c;
        }
      }

      .add {
        box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
        background: #234a98;

        i {
          background: white;
        }
      }
    }
  }

  .all-data {
    .list-table {
      .el-table {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;

        &::before {
          height: 0;
        }
      }

      .el-table tr {
        background-color: rgba(255, 255, 255, 0);
      }

      table {
        border-spacing: 0px 12px !important;
        border-collapse: separate;
      }

      table td {
        border: none;
      }

      .el-table .el-table__body-wrapper table tr td:last-child {
        color: #b3c0ce;
      }

      .el-table__header {
        border-spacing: 0px 0px !important;
        border-collapse: separate;
      }

      thead {
        th {
          border: none !important;
          background-color: rgba(255, 255, 255, 0) !important;
          padding-bottom: 0;
          padding-top: 0;
        }

        th:first-child {
          padding-left: 15px;
        }

        .cell {
          font-weight: 500;
        }
      }

      tbody {
        filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));

        .el-table__row {
          &:hover {
            background-color: white !important;
          }

          td {
            height: 60px;
          }

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding-left: 15px;
          }

          td:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }

          background-color: white !important;
          border-radius: 10px !important;
        }

        .el-button {
          border: none;
        }

        .el-table-column--selection {
          .cell {
            justify-content: center !important;
          }
        }

        td {
          .cell {
            i {
              height: 40px;
            }
          }
        }
      }
    }

    .el-dialog {
      width: 50%;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-direction: column;
      padding: 20px;

      // .el-dialog__body {
      //   padding-bottom: 0;
      //   margin-top: 18px;
      // }
      .el-icon-close:before {
        content: "";
      }

      .el-dialog__header {
        display: none;
      }

      padding: 0;
      position: relative;

      .close {
        position: absolute;
        right: 1rem;
        cursor: pointer !important;
        top: 1rem;
        width: 28px;
        height: 28px;
        padding: 3px;
        border-radius: 50%;
        background-color: #eaedf0;

        .icon-close {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #44566c;
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #8697a8;
        }

        input {
          background: #f8fafb;
          border: none;
        }

        .el-upload {
          width: 100%;
          border-color: #8697a8;
        }

        .el-select {
          width: 100%;
          border-color: #8697a8;
        }

        .text-edit {
          #toolbar {
            padding: 9px;
            border: none;
            background-color: #f8fafb !important;
            border-radius: 5px 5px 0px 0px;
            font-family: "Roboto" !important;
          }

          .ql-snow .ql-stroke {
            stroke: #8697a8;
          }

          .ql-active > .ql-snow .ql-stroke {
            stroke: #8697a8;
          }

          .ql-container {
            min-height: 100px;
            border: none !important;
            background-color: #f8fafb;
            resize: both;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #8697a8;
          }
        }
      }

      .dialog-footer {
        width: 100%;

        .buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }

          .btn-add {
            filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));

            width: 152px;
            background: #234a98;
            height: 40px;
            padding: 0;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: white;
            border: none;

            i {
              float: left;
            }
          }

          .btn-cancel {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            width: 110px;
            color: #234a98;
            background-color: #eae5f8;
            border: none;
          }
        }
      }

      .second {
        .el-date-editor--daterange.el-input__inner {
          width: 225px;
          height: 40px;
          align-items: center;
          filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
          border: none;
          border-radius: 5px;

          ::placeholder {
            color: #44566c;
            font-size: 15px;
            font-weight: 400;
          }

          i {
            line-height: unset;
          }

          .icon-calendar {
            padding: 0 15px;
            background-color: #44566c;
          }
        }
      }
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .el-radio-group {
      margin-bottom: 10px;
      margin-top: 10px;

      .el-radio {
        .el-radio__label {
          color: #234a98;
        }

        .is-checked {
          .el-radio__inner {
            border-color: #234a98;
            background-color: #234a98;
          }
        }

        .el-radio__input {
          .el-radio__inner {
            border-color: #234a98;
          }
        }
      }
    }
  }

  .el-switch__label.is-active {
    color: #234a98;
  }

  .main-price {
    margin-bottom: 24px;
    gap: 30px;
    padding: 0 10px;
    display: flex;

    .price {
      display: flex;
      gap: 7px;
      align-items: center;
      height: 50px;

      & > .main-price-title {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #44566c;
        min-width: 50px;
        text-align: right;
      }

      .is-free-style {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        background-color: #f7f9fa;
        border-radius: 7px;
        gap: 5px;
        filter: drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.2));
      }
    }

    .main-price-input {
      width: 70px;
      min-width: 70px;
    }

    .save-price-btn {
      background-color: #234a98 !important;
      filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));
      border: none;
      color: white;
      width: 125px;

      span {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;

        i {
          height: 14px;
          width: 14px;
        }
      }
    }
  }
}
</style>
